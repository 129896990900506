import { Component, OnInit } from '@angular/core';
import { collectExternalReferences } from '@angular/compiler';
import { Router } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router,public cart:CartService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.cart.getCartData();
  }
  ScrollToAboutUs() {
    this.router.navigate(['/home']).then(e => {
      if (e) {
        setTimeout(function () {
          this.services = document.getElementById("services2-e");
          window.addEventListener("scroll", this.parallax, false);
            console.log('')
            $("html, body").animate({ scrollTop: this.services.offsetTop }, 600);
            return false;
        }, 300);
      } else {
        setTimeout(function () {
          this.services = document.getElementById("services2-e");
          window.addEventListener("scroll", this.parallax, false);
            console.log('')
            $("html, body").animate({ scrollTop: this.services.offsetTop }, 600);
            return false;
        }, 300);
      }
      //console.log("how");
    });
  }
  
  goToCart(){
    if(window.location.pathname=='/cart'){

    }else{
    if(this.cart.cart_Data.length == 0){
      this.toastr.error('Your cart is empty');
     }
     else{
      this.router.navigate(['/cart']);
     }
    }
  }
}
