import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';

@Component({
  selector: 'app-e-books',
  templateUrl: './e-books.component.html',
  styleUrls: ['./e-books.component.scss']
})
export class EBooksComponent implements OnInit {

  singleEbook: any;

  constructor(
    public apiservice: ApiService,
    public constantService: ConstantsService,
    public router: Router,
    public cart: CartService) { }

  ngOnInit(): void {
    let productId = this.router.url.split('/')[2];
    this.getSingleItems(productId);
  }

  getSingleItems(id: string) {
    this.apiservice.getRequest(this.constantService.SINGLEITEM + id, '').then((res: any) => {
      this.singleEbook = res['data'];
      console.log(this.singleEbook);
    }).catch((err: any) => {
      console.log(err)
    })
  }

}
