import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  public guestdetail: any;
  public orderss: any;

  constructor() { }

  SLIDER = 'client/slider';
  CATEGORY = 'client/category';
  PRODUCT = 'client/product';
  CONTACT = 'client/contact';
  GUEST = 'client/guest';
  ORDER = 'client/order';
  ORDERGET = 'client/order';
  TOKEN = 'client/token';
  COUPON = 'client/coupan-apply';
  REMOVECOUPON = 'client/remove-coupan';
  GETCOUPON = 'client/coupan-code';
  CART = 'client/cart';
  TESTIMONIAL = 'client/feedback';
  SINGLEITEM = 'client/product/';
  CONTTESTITEM = 'client/product/';

}

