import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.scss']
})
export class ContestComponent implements OnInit {

  singleContest: any;

  constructor(
    public apiservice: ApiService,
    public constantService: ConstantsService,
    public router: Router,
    public cart: CartService) { }

  ngOnInit(): void {
    let productId = this.router.url.split('/')[2];
    this.getSingleItems(productId);
  }

  getSingleItems(id: string) {
    this.apiservice.getRequest(this.constantService.SINGLEITEM + id, '').then((res: any) => {
      this.singleContest = res['data'];
      console.log(this.singleContest);
    }).catch((err: any) => {
      console.log(err)
    })
  }

}
