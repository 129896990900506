import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../service/constant-service'
import { ApiService } from '../service/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var Swiper: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sliderFetch: any;
  categoryFetch: any;
  caregoryshow: any;
  firstname: any;
  lastname: any;
  emailid: any;
  msg: any;

  //Form Group
  public addFrom: FormGroup;

  constructor(public router: Router,
    private apiservices: ApiService,
    public constantsService: ConstantsService,
    public FormBuilder: FormBuilder) {
    // this.setForm();
  }

  // setForm() {
  //   this.addFrom = this.FormBuilder.group({  //"^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
  //     FName: new FormControl('', [Validators.required]),
  //     LName: new FormControl('', [Validators.required]),
  //     Email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
  //     Message: new FormControl('', [Validators.required]),
  //   })
  // }

  ngOnInit(): void {
    this.initCall();
    this.swiperInitCall();
  }

  initCall() {
    this.Slider();
    this.Category();
  }

  swiperInitCall() {
    var swiper = new Swiper('.swiper-container1', {
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination1',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next1',
        prevEl: '.swiper-button-prev1',
      },
    });
  }

  routing(data) {
    if (data == 'Training Programs') {
      this.router.navigate(['/shop/lifestyle-general-fitness'])
    }
    else if (data == 'E-Books') {
      this.router.navigate(['/shop/ebooks'])
    }
    else if (data == 'Merchandise') {
      this.router.navigate(['/shop/merchandise'])
    }
  }

  Slider() {
    var data = ''
    this.apiservices.getRequest(this.constantsService.SLIDER, data).then(res => {
      this.sliderFetch = res['data'];
    })
  }

  Category() {
    setTimeout(() => {
      this.categoryFetch = [];
      var data = ''
      this.apiservices.getRequest(this.constantsService.CATEGORY, data).then(res => {

        //console.log(res);
        for (let j of res['data']) {
          if (j.categoryPhoto != null) {
            this.categoryFetch.push(j);
            //console.log(this.categoryFetch);
            // this.spinner.hide();
          }
        }
      })
    }, 1000);

  }

}