import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public Quantity: any = 1;
  public getcartdata: any;

  constructor(
    public cart: CartService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.cart.getCartData();
    }, 1000);
  }

  quantitychange(e, id, stock, shop) {
    this.Quantity = e.target.value;
    if (this.Quantity <= stock) {
      this.cart.updateQunatity(id, this.Quantity, shop)
    } else {
      this.apiService.toastr.error("Quantity Unavailable.");
      this.cart.getCartData();
    }
  }

}
