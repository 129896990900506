import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { ConstantsService } from './constant-service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart_Data: any[] = [];
  productDetails: any;
  totalamount: any;
  getPrice: number;
  gettotalamontforpay: any = 0;
  getsaleprice: any;
  gettotalsaleamontforpay: any;
  getdiscount: any;
  gettotaldiscount: any;
  gettotalamont: any;
  getProductPrice: any;

  constructor(public api: ApiService,
    public constant: ConstantsService,
    private toastr: ToastrService) { }

  addTocart(productVarientId) {
    let data = { 'shopId': productVarientId, 'deviceIdentifier': this.api.deviceIdentifier }
    this.api.postRequest(this.constant.CART, data).then(res => {
      this.toastr.success(res['message']);
      this.getCartData();
    }).catch(err => {
      this.toastr.error(err.error['error'])
    })
  }

  addTocartMerch(productVarientId, Size) {
    let data = { 'shopId': productVarientId, 'deviceIdentifier': this.api.deviceIdentifier, 'size': Size }
    this.api.postRequest(this.constant.CART, data).then(res => {
      // console.log(res);
      this.toastr.success(res['message']);
      this.getCartData();
    })
      .catch(err => {
        this.toastr.error(err.error['message'])
      })
  }

  getCartData() {
    this.api.getRequest(this.constant.CART, "?deviceIdentifier=" + this.api.deviceIdentifier).then(res => {
      // if (res) {
      // console.log(res);
      this.cart_Data = res['data'];
      this.totalamount = res['data'];
      this.getPrice = 0;
      this.getProductPrice = 0;
      this.getdiscount = 0;
      for (let i of this.totalamount) {
        if (i.codeType == 'FLAT') {
          let price = i.shop.salePrice ? i.shop.salePrice : i.shop.price;
          i.price = price;
          this.getProductPrice += price * i.quantity; //Price X Item
          this.getPrice += (price * i.quantity) - i.discount;
          this.getsaleprice += i.shop.salePrice;
          this.getdiscount += i.discount;
        }
        else if (i.codeType == 'PERCENTAGE') {
          let price = i.shop.salePrice ? i.shop.salePrice : i.shop.price;
          i.price = price;
          this.getProductPrice += price * i.quantity; //Price X Item
          this.getPrice += (price * i.quantity) - ((price * i.quantity) * i.discount) / 100;
          this.getsaleprice += i.shop.salePrice;
          this.getdiscount += ((price * i.quantity) * i.discount) / 100;
        }
        else {
          let price = i.shop.salePrice ? i.shop.salePrice : i.shop.price;
          i.price = price;
          this.getProductPrice += price * i.quantity; //Price X Item
          this.getPrice += price * i.quantity; //Price X Item
          this.getsaleprice += i.shop.salePrice;
        }
      }
      this.gettotalamont = Math.round(this.getProductPrice);
      this.gettotalamontforpay = Math.round(this.getPrice);
      this.gettotalsaleamontforpay = Math.round(this.getsaleprice);
      this.gettotaldiscount = Math.round(this.getdiscount);
      // }
      // console.log(this.getProductPrice);
    }).catch(err => {
      // console.log(err)
    });
  }

  //start substract the quantity
  updateQunatitysubbtn(cartid, qunatityinput, shopId) {
    if (qunatityinput != 1) {
      var quantityadd = qunatityinput;
      // // console.log(quantityadd);
      var data = { "quantity": quantityadd, 'deviceIdentifier': this.api.deviceIdentifier, 'shopId': shopId }
      this.api.putRequest(this.constant.CART + '/' + cartid, data).then(res => {
        // // console.log(res);
        this.getCartData();
      }).catch(err => {
        // console.log(err)
      })
    }
  }
  //end substract the quantity

  //start add the quantity
  updateQunatityaddbtn(cartid, qunatityinput, shopId) {
    var quantityadd = Number(qunatityinput) + 1;
    var data = { "quantity": quantityadd, 'deviceIdentifier': this.api.deviceIdentifier, 'shopId': shopId }
    this.api.putRequest(this.constant.CART + '/' + cartid, data).then(res => {
      // // console.log(res);
      this.getCartData();
    }).catch(err => {
      // console.log(err)
    })
  }
  //end add the quantity

  // start Update Qunatity
  updateQunatity(cartid, qunatityinput, shopId) {
    if (qunatityinput >= 1) {
      var data = { "quantity": qunatityinput, 'deviceIdentifier': this.api.deviceIdentifier, 'shopId': shopId }
      this.api.putRequest(this.constant.CART + '/' + cartid, data).then(res => {
        // // console.log(res);
        this.getCartData();
      }).catch(err => {
        // console.log(err)
      })
    }
    else if (qunatityinput == 0) {
      this.cartremove(cartid, shopId)
    }

  }

  cartremove(cartid, shopId) {
    this.api.deleteRequest(this.constant.CART + '?deviceIdentifier=' + this.api.deviceIdentifier + '&id=' + cartid + '&shopId=' + shopId, '').then(res => {
      // // console.log(res);
      this.toastr.success(res['message']);
      this.getCartData();
    }).catch(err => {
      // console.log(err)
    })
  }

}
