<section class="cid-rYR8Zqg8A2 mbr-reveal fixedbig" id="footer1-b">
    <div class="container">
        <div class="media-container-row content text-white">
            <div class="col-12 col-md-3">
                <div class="media-wrap">
                    <a routerLink="/">
                        <img src="assets/images/logosm.png" alt="Junaid Kaliwala" title="Junaid Kaliwala">
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-3 mbr-fonts-style display-7">
                <h5 class="pb-3">
                    Note</h5>
                <p class="mbr-text text-center text-md-left">All personal programs are sent by E-Mail.</p>
            </div>
            <div class="col-12 col-md-3 mbr-fonts-style display-7">
                <h5 class="pb-3">
                    Contact Details</h5>
                <p class="mbr-text text-center text-md-left">
                    Collaboration/Event/Business<br>Email: <a
                        href="mailto:info@junaidkaliwala.com">info@junaidkaliwala.com</a><br><br><a
                        routerLink="/contact">Contact Me</a><a href="mailto:info@junaidkaliwala.com"><br></a><br></p>
            </div>
            <div class="col-12 col-md-3 mbr-fonts-style display-7">
                <h5 class="pb-3">
                    Links
                </h5>
                <p class="mbr-text text-center text-md-left">
                    <a (click)="ScrollToAboutUs()" style="color: #c59e3e;">About Me</a><br>
                    <a (click)="routingTemp()">Shop</a><br>
                    <a routerLink="/terms-and-conditions">Terms &amp; Conditions</a><br>
                    <a routerLink="/privacy-policy">Privacy Policy</a><br>
                    <a routerLink="/refund-policy">Return Policy</a></p>
            </div>
            <div class="col-12 d-flex d-lg-none">
                <p class="mbr-text mbr-fonts-style">
                    <span class="text-justify">Junaid Kaliwala or Representing Website is not responsible for
                    any Injuries, Illnesses, Diseases and any other Health Problems Suffered by Customers who
                    Purchased any of the provided products or services.</span><br>
                </p>
            </div>
        </div>
        <div class="footer-lower">
            <div class="media-container-row">
                <div class="col-sm-12">
                    <hr>
                </div>
            </div>
            <div class="media-container-row mbr-white">
                <div class="col-sm-9 col-lg-6 copyright">
                    <p class="mbr-text mbr-fonts-style display-7">
                        © Copyright 2021 Junaid Kaliwala - All Rights Reserved <br><em><a
                                href="https://www.kenmarkitan.com" target="_blank">Powered by Kenmark ITan
                                Solutions</a></em><br><br><span class="d-none d-lg-flex">Junaid Kaliwala or Representing Website is not responsible for
                        any Injuries, Illnesses, Diseases and any other Health Problems Suffered by Customers who
                        Purchased any of the provided products or services.</span><br>
                    </p>
                </div>
                <div class="col-sm-3 col-lg-6">
                    <div class="social-list align-right">
                        <div class="soc-item">
                            <a href="https://www.instagram.com/ifbbpro_jkaliwala/" target="_blank">
                                <span class="mdi mdi-instagram"
                                    style="color: rgb(197, 158, 62); fill: rgb(197, 158, 62);"></span>
                            </a>
                        </div>
                        <div class="soc-item">
                            <a href="https://www.facebook.com/jkaliwala/" target="_blank">
                                <span class="mdi mdi-facebook"
                                    style="color: rgb(197, 158, 62); fill: rgb(197, 158, 62);"></span>
                            </a>
                        </div>
                        <div class="soc-item">
                            <a href="https://www.youtube.com/channel/UC155cQcO4XVLimywTrImzsg" target="_blank">
                                <span class="mdi mdi-youtube"
                                    style="color: rgb(197, 158, 62); fill: rgb(197, 158, 62);"></span>
                            </a>
                        </div>



                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>