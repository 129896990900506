<app-header></app-header>
<div class="footerspace">
    <!-- <router-outlet></router-outlet> -->
    <div class="first">
        <router-outlet></router-outlet>
      </div>
      <div class="second">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<a href="https://api.whatsapp.com/send?phone=+917506721241&text=Hi%2C%0AI%20would%20like%20to%20discuss%20about%20training%20program!." class="float" target="_blank">
<i class="fa fa-whatsapp my-float">&nbsp;Chat with us</i>
</a>
        <router-outlet></router-outlet>
      </div>
</div>
<app-footer></app-footer>