import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { SharedModule } from './shared/shared.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrdersucessComponent } from './ordersucess/ordersucess.component';
import { EbookDownloadComponent } from './ebook-download/ebook-download.component';
import { CartComponent } from './cart/cart.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { OTComponent } from './ot/ot.component';
import { ContestComponent } from './contest/contest.component';
import { EBooksComponent } from './e-books/e-books.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'shop/:id', component: ShopComponent },
  { path: 'news', component: NewsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'thankyou', component: OrdersucessComponent },
  { path: 'ebook-download/:id', component: EbookDownloadComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: "cart", component: CartComponent },
  { path: "testimonial", component: TestimonialComponent },
  { path: "ot/:id", component: OTComponent },
  { path: "contest/:id", component: ContestComponent },
  { path: "e-books/:id", component: EBooksComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), SharedModule],
  exports: [RouterModule, SharedModule]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['/']);
    }
  }
}
