<section class="mbr-instagram-feed" data-rows="3" data-per-row-slider="" data-spacing="5" data-full-width="true"
    data-token="259271193.727bfe1.910fc015aeca4a11b9987073eebd21b3" data-per-row-grid="6" id="instagram-feed-block-x"
    data-rv-view="152" style="background-color: rgb(250, 250, 250); padding-top: 160px; padding-bottom: 160px;">
    <div class="container">
        <div class="row mt-3" *ngIf="  singleContest">
            <div class="col-12 col-md-5 ">
                <img class="img-fluid ml ml1" src="{{singleContest.productPhoto}}">
            </div>
            <div class="col-12 col-md-6 ms-md-5 py-3 ">
                <div class="row">
                    <div class="col-12 ">
                        <h4>{{  singleContest.productName}}</h4><br>
                        <h5 *ngIf="singleContest.salePrice != null"><del> ₹{{singleContest.price}}</del> &nbsp; ₹{{singleContest.salePrice}}</h5>
                        <h5 *ngIf="singleContest.salePrice == null"> ₹{{singleContest.price}}</h5><br>
                        <p [innerHtml]="singleContest.productDescription"></p>
                        <div class="mt-4 mb-2">
                            <button class="btn btn-primary display-4" (click)="cart.addTocart(singleContest.id)">Add to Cart

                            </button>
                        </div>
                        <br><br><br>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>