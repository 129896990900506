<section class="services1 cid-rYRoOy9ztz p-0">
    <div class="container pt-5">
        <div class="row justify-content-center pt-5">
            <!--Titles-->
            <div class="title col-12">
                <h2 class="align-center pb-3 mbr-fonts-style display-1" style="color:black">
                    Testimonials & Transformations</h2>

            </div>
            <!--Card-1-->
            <div class="card col-12 col-md-6 col-lg-4 Colempty" id="checkcol" *ngFor="let i of testimonialFetch">
                <div class="card-wrapper p-3">
                    <div class="card-img">
                        <img src="{{i.photo}}" alt="" title="">
                    </div>
                    <div class="card-box " style="padding-bottom: 100px;">
                        <h4 class="card-title mbr-fonts-style display-5">{{i.fullName}}</h4>
                        <p class="mbr-text mbr-fonts-style display-7">{{i.message}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>