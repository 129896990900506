import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { HttpClientModule } from '@angular/common/http';
import { CheckoutComponent } from './checkout/checkout.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OrdersucessComponent } from './ordersucess/ordersucess.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EbookDownloadComponent } from './ebook-download/ebook-download.component';
import { CartComponent } from './cart/cart.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { OTComponent } from './ot/ot.component';
import { ContestComponent } from './contest/contest.component';
import { EBooksComponent } from './e-books/e-books.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ShopComponent,
    NewsComponent,
    ContactComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    CheckoutComponent,
    OrdersucessComponent,
    EbookDownloadComponent,
    CartComponent,
    TestimonialComponent,
    OTComponent,
    ContestComponent,
    EBooksComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ timeOut: 2000 })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
