<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>
<section class="engine">
    <a href="https://mobirise.info/u">bootstrap html templates</a>
</section>
<section class="mbr-section form1 cid-rYR4j0zXqP" id="form1-9">
    <div class="container">
        <div class="row justify-content-center">
            <div class="title col-12 col-lg-8">
                <h2 class="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                    CONTACT FORM
                </h2>
                <h3 class="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">
                    Fill the form to get in touch with Junaid Kaliwala's Team</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="media-container-column col-lg-8" data-form-type="formoid">
                <!---Formbuilder Form--->
                <form [formGroup]="contactForm" autocomplete="off">
                    <div class="row">
                        <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Thanks for filling
                            out the form!</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                        </div>
                    </div>
                    <div class="dragArea row">
                        <div class="col-md-4  form-group" data-for="name">
                            <label for="name-form1-9" class="form-control-label mbr-fonts-style display-7">Name*</label>
                            <input type="text" name="name" formControlName="Name" data-form-field="Name"
                                required="required" class="form-control display-7" id="Name">
                            <div *ngIf="contactForm.get('Name').invalid && (contactForm.get('Name').dirty || contactForm.get('Name').touched)"
                                class="alert">
                                <div *ngIf="contactForm.get('Name').errors.required">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4  form-group" data-for="email">
                            <label for="email-form1-9"
                                class="form-control-label mbr-fonts-style display-7">Email*</label>
                            <input type="email" name="email" formControlName="Email" data-form-field="Email"
                                required="required" class="form-control display-7" id="Email">
                            <div *ngIf="contactForm.get('Email').invalid && (contactForm.get('Email').dirty || contactForm.get('Email').touched)"
                                class="alert">
                                <div *ngIf="contactForm.get('Email').errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="contactForm.get('Email').errors.pattern">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-md-4  form-group">
                            <label for="phone-form1-9"
                                class="form-control-label mbr-fonts-style display-7">Phone</label>
                            <input type="tel" name="phone" formControlName="Phone" data-form-field="Phone"
                                class="form-control display-7" id="Phone">
                            <div *ngIf="contactForm.get('Phone').invalid && (contactForm.get('Phone').dirty || contactForm.get('Phone').touched)"
                                class="alert">
                                <div *ngIf="contactForm.get('Phone').errors.maxlength">
                                    Phone number should be 10 digits.
                                </div>
                                <div *ngIf="contactForm.get('Phone').errors.minlength">
                                    Phone number should not less than 10 digits.
                                </div>
                                <div *ngIf="contactForm.get('Phone').errors.pattern">
                                    Please enter valid mobile number.
                                </div>
                            </div>
                        </div>
                        <div data-for="message" class="col-md-12 form-group">
                            <label for="message-form1-9"
                                class="form-control-label mbr-fonts-style display-7">Message*</label>
                            <textarea name="message" formControlName="Message" data-form-field="Message"
                                class="form-control display-7" id="Message"></textarea>
                            <div *ngIf="contactForm.get('Message').invalid && (contactForm.get('Message').dirty || contactForm.get('Message').touched)"
                                class="alert">
                                <div *ngIf="contactForm.get('Message').errors.required">
                                    Message is required.
                                </div>
                            </div>
                        </div>
                        <span class="gdpr-block">
                            <label>
                                <span class="textGDPR" style="color: #a7a7a7"><input formControlName="terms"
                                        type="checkbox" name="gdpr" id="gdpr-form1-9" required="">By continuing you
                                    agree to our <a style="color: #c59e3e; text-decoration: underline;"
                                        href="terms-and-conditions.html">Terms of Service</a> and <a
                                        style="color: #c59e3e; text-decoration: underline;"
                                        href="privacy-policy.html">Privacy Policy</a>.</span>
                            </label>
                        </span>

                        <div class="col-md-12 input-group-btn align-center">
                            <button type="submit" class="btn btn-primary btn-form display-4"
                                [disabled]="!contactForm.valid" (click)="addContact()">SEND FORM</button>
                        </div>
                    </div>
                </form>
                <!---Formbuilder Form--->
            </div>
        </div>
    </div>
</section>