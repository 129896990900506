import { Injectable } from '@angular/core';
import { openDB } from 'idb';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _dbPromise: any;
  private dbName = 'junaidKaliwalaDB';

  async connectToIDB() {
    let ngThis = this;
    this._dbPromise = await openDB(this.dbName, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(ngThis.dbName)) {
          db.createObjectStore(ngThis.dbName);
        }
      },
    });
  }

  async addItem(key: any, val: any) {
    await this.connectToIDB();
    const tx = await this._dbPromise.transaction(this.dbName, 'readwrite');
    return (await tx.objectStore(this.dbName)).put(val, key);
  }

  async getItem(key: any) {
    await this.connectToIDB();
    return (await this._dbPromise).get(this.dbName, key);
  }

  async deleteItem(key: any) {
    await this.connectToIDB();
    return (await this._dbPromise).delete(this.dbName, key);
  }

  async clear() {
    await this.connectToIDB();
    return (await this._dbPromise).clear(this.dbName);
  }

  async updateItem(key: any, val: any) {
    return this.deleteItem(key).finally(() => {
      return this.addItem(key, val);
    });
  }

}
