import { Component } from '@angular/core';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  //Form Group
  public contactForm: FormGroup;

  constructor(private apiService: ApiService,
    public constant: ConstantsService,
    public FormBuilder: FormBuilder) {
    this.setForm();
  }

  setForm() {
    this.contactForm = this.FormBuilder.group({
      Name: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$")]),
      Phone: new FormControl('', Validators.compose([Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[6-9]\d{9}$/)])),
      Message: new FormControl('', [Validators.required]),
      terms: new FormControl('')
    })
  }

  addContact() {
    if (this.contactForm.invalid) {
      this.apiService.toastr.error("Please fill (*) mark fields.");
      return;
    }
    let data = { "name": this.contactForm.value.Name, "email": this.contactForm.value.Email, "phone": this.contactForm.value.Phone, "message": this.contactForm.value.Message }
    this.apiService.postRequest(this.constant.CONTACT, data).then(res => {
      this.apiService.toastr.success('thank you for contacting us');
    }).catch(err => {
      this.apiService.toastr.error(err['message']);
    })
  }

}
