import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartService } from '../service/cart.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, AfterViewInit{
  services: HTMLElement;
  categoryFetch: any;
  productFetch: any;
  currentLocation: string;
  hashid: string;
  merchsize: any;
  selectedsizeid: any;

  selectedPage:any;

  constructor(
    public route: ActivatedRoute,public toastr: ToastrService, public cart: CartService, private apiservices: ApiService, public constantsService: ConstantsService, public router: Router, private spinner: NgxSpinnerService) { }
  ngAfterViewInit(): void {
    this.route.params.subscribe(routeParams => {
      if(routeParams.id=='lifestyle-general-fitness'){
        this.selectedPage = 2;
       setTimeout(() => {
        this.services = document.getElementById("2");
        $("html, body").animate({ scrollTop: this.services?.offsetTop }, 0);
       }, 1000 );
      // 1000
      }
      if(routeParams.id=='contest-prep'){
        this.selectedPage = 3;
       setTimeout(() => {
        this.services = document.getElementById("3");
        $("html, body").animate({ scrollTop: this.services?.offsetTop }, 0);
       }, 1000 );
      }
      if(routeParams.id=='ebooks'){
        this.selectedPage = 4;
        setTimeout(() => {
         this.services = document.getElementById("4");
         $("html, body").animate({ scrollTop: this.services?.offsetTop }, 0 );
        }, 1000 );
       }
       if(routeParams.id=='merchandise'){
        this.selectedPage = 5;
        setTimeout(() => {
         this.services = document.getElementById("5");
         $("html, body").animate({ scrollTop: this.services?.offsetTop }, 0 );
        }, 1000 );
       }
    });
    
  }

  ngOnInit() {
    // this.spinner.show();
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
        /* this.route.params.subscribe(routeParams => {
           if(routeParams.id=='lifestyle-general-fitness'){
            this.services = document.getElementById("1");
            $("html, body").animate({ scrollTop: this.services.offsetTop }, 0);
           }
           if(routeParams.id=='contest-prep'){
            this.services = document.getElementById("2");
            $("html, body").animate({ scrollTop: this.services.offsetTop }, 0);
           }
         }); */
    //   }
    // });
    this.Category();
    this.Product();
  }

  Category() {
    var data = ''
    this.apiservices.getRequest(this.constantsService.CATEGORY, data).then(res => {
      //console.log(res);
      this.categoryFetch = res['data'];
    })
  }

  Product() {
    var data = ''
    this.apiservices.getRequest(this.constantsService.PRODUCT, data).then(res => {
      //console.log(res);
      this.productFetch = res['data'];
      // this.spinner.hide();
    })
  }

  gotoCheckout(id, name) {
    //console.log(id);
    if (id != '') {
      try {
        this.router.navigate(['/checkout', id]);
      } catch (error) { }
    } else {
      alert('No slug found for article ' + name);
    }
  }

  Getsize(size, id) {

    for (let j of this.productFetch) {

      if (j.id == id && size) {
        j.colors = '#c59e3e';
        j.size = size;
        console.log(j)
      }
      else {
        j.colors = null;
        j.size = null;
      }

    }
     this.selectedsizeid=id;
    // //  addactive.target.classList.add('borderactive');
      this.merchsize=size;
    //   // console.log(addactive)
  }

  Setsize() {
    this.toastr.error('Please Select A Size');
  }

  AddMerchtocart(id) {
    if (id == this.selectedsizeid) {
      this.cart.addTocartMerch(id, this.merchsize)
    }
    else {
      this.toastr.error('Please Select A Size');
    }
  }

}
