import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';

@Component({
  selector: 'app-ebook-download',
  templateUrl: './ebook-download.component.html',
  styleUrls: ['./ebook-download.component.scss']
})
export class EbookDownloadComponent implements OnInit {

  currentLocation: string;
  shopid: any;
  filedownload: any;
  messagecheck: any;
  productFetch: any;

  constructor(private apiService: ApiService,
    public constant: ConstantsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.currentLocation = window.location.pathname;
    let token = this.currentLocation.split('/')[2];
    this.verifyToken(token);
  }

  verifyToken(token: string) {
    this.spinner.show();
    let data = { "token": token }
    try {
      this.apiService.postRequest(this.constant.TOKEN, data).then(res => {
        this.messagecheck = res['message'];
        if (this.messagecheck == 'Link verified') {
          this.shopid = res['shopId'];
          this.filedownload = res['downloadFile'];
          this.productDetail();
          this.spinner.hide();
        }
      });
    } catch (error) {
      this.spinner.hide();
    }
  }

  productDetail() {
    this.apiService.getRequest(this.constant.PRODUCT, '').then(res => {
      for (let i of res['data']) {
        if (i.id == this.shopid) {
          this.productFetch = i;
          this.spinner.hide();
        }
      }
    }).catch(err => { console.log(err); })
  }

  downloadMyFile() {
    if (this.filedownload == 'assets/pdf/combo1.zip' || this.filedownload == 'assets/pdf/combo2.zip' || this.filedownload == 'assets/pdf/combo3.zip') {
      var link = document.createElement('a');
      link.href = this.filedownload;
      link.download = this.productFetch.productName + '.zip';
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      var link = document.createElement('a');
      link.href = this.filedownload;
      link.download = this.productFetch.productName + '.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    }
  }

}
