import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  ScrollToAboutUs() {
    this.router.navigate(['/home']).then(e => {
      if (e) {
        setTimeout(function () {
          this.services = document.getElementById("services2-e");
          //console.log(this.services.offsetTop);
          window.scrollTo(0, this.services.offsetTop);
        }, 100);
      } else {
        setTimeout(function () {
          this.services = document.getElementById("services2-e");
          //console.log(this.services.offsetTop);
          window.scrollTo(0, this.services.offsetTop);
        }, 100);
      }
      //console.log("how");
    });
  }

  routingTemp(){
    console.log('Hii');
    this.router.navigate(['/shop'])
  }

}
