<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
    </ngx-spinner>
    <section class="engine"><a href="https://mobirise.info/r">bootstrap template</a></section>
    <section class="mbr-instagram-feed" data-rows="3" data-per-row-slider="" data-spacing="5" data-full-width="true"
    data-token="259271193.727bfe1.910fc015aeca4a11b9987073eebd21b3" data-per-row-grid="6" id="instagram-feed-block-x"
    data-rv-view="152" style="background-color: rgb(250, 250, 250); padding-top: 160px; padding-bottom: 160px;">
<div class="container">
    <div class="row mt-3"  *ngIf="onlineTraining">
        <div class="col-12 col-md-5 "><img class="img-fluid" src="{{onlineTraining.productPhoto}}"></div>
        <div class="col-12 col-md-6 ms-md-5 py-3 ">
            <div class="row">
                <div class="col-12 ">
                    <h4>{{onlineTraining.productName}}</h4><br> 
                    <h5 *ngIf="onlineTraining.salePrice != null" ><del> ₹{{onlineTraining.price}}</del> &nbsp; ₹{{onlineTraining.salePrice}}</h5><br>
                    <h5 *ngIf="onlineTraining.salePrice == null" > ₹{{onlineTraining.price}}</h5><br>
                    <p [innerHtml]="onlineTraining.productDescription"></p>
                    <div class="mt-4 mb-2"><button class="btn btn-primary display-4 group1 " (click)="cart.addTocart(onlineTraining.id)">Add to Cart </button></div>
                </div>
            </div>
        </div>
    </div>

</div>
</section>