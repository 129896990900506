<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>
<section class="engine">
    <a href="https://mobirise.info/u">bootstrap html templates</a>
</section>
<section class="mbr-section form1 cid-rYR4j0zXqP" *ngIf="cart.cart_Data" id="form1-9">
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="cart.cart_Data.length>=1">
                    <p class="conshop" routerLink="/shop">Continue shopping</p>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" *ngIf="cart.cart_Data.length>=1">
                    <h2>CART</h2>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" *ngIf="cart.cart_Data.length==0">
                    <h2>YOUR CART IS EMPTY</h2>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center  py-5" *ngIf="cart.cart_Data.length>=1">
                    <div class="row d-none d-lg-flex">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-sm-6 col-md-6 col-lg-6 orderTitles text-left">
                                            <p class="mb-0">Product</p>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 orderTitles">
                                            <div class="row justify-content-center">
                                                <div class="col-sm-4 col-md-4 col-lg-4 text-left">
                                                    <p class="mb-0">Price</p>
                                                </div>
                                                <div class="col-sm-4 col-md-4 col-lg-4 text-left">
                                                    <p class="mb-0">Quantity</p>
                                                </div>
                                                <div class="col-sm-4 col-md-4 col-lg-4 text-left">
                                                    <p class="mb-0">Subtotal</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12" *ngFor="let i of cart.cart_Data">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-lg-6 text-left">
                                            <div class="row dflex align-items-center">
                                                <div class="col-md-1 col-lg-1 text-center">
                                                    <h3 style="cursor: pointer;"
                                                        (click)="cart.cartremove(i.id,i.shopId)"><i
                                                            class="mdi mdi-trash-can-outline"></i>
                                                    </h3>
                                                </div>
                                                <div class="col-lg-3">
                                                    <img src={{i.shop.productPhoto}} class="img-fluid">
                                                </div>
                                                <div class="col-lg-8">
                                                    <p><strong class="d-flex d-lg-none">Product Name:
                                                        </strong>{{i.shop.productName}}<span *ngIf="i.size">
                                                            ({{i.size}})</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-4 text-left">
                                                    <p><strong class="d-flex d-lg-none">Product Price:
                                                        </strong>{{i.shop.price|currency:'INR':'symbol':'3.0-0'}}</p>
                                                </div>
                                                <div class=" col-lg-4 text-left">
                                                    <strong class="d-flex d-lg-none">Quantity: </strong>
                                                    <div class="input-group" *ngIf="i.shop.categoryId==5">
                                                        <input type="number" name="phone" data-form-field="Phone"
                                                            class="form-control" min="1" [max]=i.shop[i.size]
                                                            [value]=i.quantity
                                                            (change)="quantitychange($event,i.id, i.shop[i.size], i.shop.id)"
                                                            style="padding: 3px;min-height: 20px;">
                                                        <!-- <div class="input-group-append">
                                                          <span class="input-group-text btnview pointer" (click)="updateQuantity(i.id, i.shop[i.size], i.shop.id)">
                                                            <i class="mdi mdi-check" style="font-size: 1.3rem;"></i>
                                                        </span>
                                                        </div> -->
                                                    </div>

                                                    <input type="number" name="phone" data-form-field="Phone"
                                                        *ngIf="i.shop.categoryId!=5" class="form-control w-100"
                                                        value="1" style="padding: 3px;min-height: 20px;" readonly>
                                                </div>
                                                <div class="col-lg-4 text-left">
                                                    <p><strong class="d-flex d-lg-none">Total Price:
                                                        </strong>{{i.shop.salePrice *
                                                        i.quantity|currency:'INR':'symbol':'3.0-0'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 w-100 justify-content-start">
                            <div class="row justify-content-end">
                                <div class="col-sm-12 col-md-6 text-right">
                                    <button type="submit" class="btn btn-primary btn-form display-4 pointer"
                                        routerLink="/checkout">CHECKOUT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center  py-5" *ngIf="cart.cart_Data.length==0">
                    <div class="row">
                        <div class="col-12 w-100 justify-content-start">
                            <img src="/assets/images/emptycart.jpg" class="img-fluid w-100">
                        </div>
                        <div class="col-12 w-100 mt-5 justify-content-start">
                            <button type="submit" class="btn btn-primary btn-form display-4 pointer"
                                routerLink="/shop">SHOP NOW</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>