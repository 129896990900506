<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
    </ngx-spinner>
    <section class="engine"><a href="https://mobirise.info/r">bootstrap template</a></section>
<section class="mbr-instagram-feed" data-rows="3" data-per-row-slider="" data-spacing="5" data-full-width="true"
    data-token="259271193.727bfe1.910fc015aeca4a11b9987073eebd21b3" data-per-row-grid="6" id="instagram-feed-block-x"
    data-rv-view="152" style="background-color: rgb(250, 250, 250); padding-top: 160px; padding-bottom: 160px;">

    <div class="container container_toggle">
        <div class="row">
            <div class="col">
                <div class="inst">
                    <h1 class="inst__title align-center mbr-fonts-style display-2 mb-4">Instagram Feed</h1>
                    <div class="inst__content">
                        <div id="instagram-feed1" class="instagram_feed"></div>
                        </div>
                    <div class="inst__more mbr-section-btn align-center ml "  buttons="0"><a
                            class="btn btn-md btn-primary display-4"  href="https://www.instagram.com/ifbbpro_jkaliwala/">Show more</a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container container_toggle mt-5">
        <div class="row">
            <div class="col">
                <div class="inst">
                    <h1 class="inst__title align-center mbr-fonts-style display-2">Youtube Feed</h1>
                    <div class="inst__content">
                        <div class="row my-3">
                            <div class="col-12 col-md-6">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/videoseries?list=UUWiMg25RuszRBplnEXMynfA&" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/videoseries?list=UU155cQcO4XVLimywTrImzsg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                      </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</section>
