<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>
<section class="services1 cid-rYRoOy9ztz p-0 pb-5 my-5">
    <div class="container pt-5" *ngIf="messagecheck == 'Link verified'">
        <div class="row my-5">
            <div class="col-12 text-center" style="border-bottom: 1px solid black;">
                <h1 class="py-3">Your ebook is ready to download!</h1>
            </div>
            <div class="col-12 col-md-6 py-5">
                <img [src]="productFetch?.productPhoto" class="img-fluid w-100">
            </div>
            <div class="col-12 col-md-6 py-5">
                <h2>{{productFetch?.productName}}</h2>
                <p class="text-justify"><span [innerHTML]="productFetch?.productDescription"></span></p>
                <button class="btn btn-dark float-right py-2 px-3 mt-5" (click)="downloadMyFile()">
                    Download Now &nbsp;<i class="mdi mdi-cloud-download mdi-36px"></i></button>
            </div>
        </div>
    </div>
    <div class="container pt-5" *ngIf="messagecheck == 'Link has expired, please try again'">
        <div class="row my-5">
            <div class="col-12 text-center" style="border-bottom: 1px solid black;">
                <h1 class="py-3">We are sorry to inform you,</h1>
                <h1>Your ebook link is expired</h1>
            </div>
        </div>
    </div>
</section>