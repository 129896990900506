<section class="menu cid-rYS1bEygpq" once="menu" id="menu2-u">
    <nav
        class="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
        <i class="mdi mdi-cart-outline ml-auto mr-5 mdi-36px d-flex d-lg-none position-absolute" (click)="goToCart()"
            style="cursor: pointer;top: 1.1rem;
        right: 1rem;">
            <span class="badge badge-dark"
                style="position: absolute;right: 0px;top: -10px;border-radius: 15px;font-style: normal;"
                *ngIf="cart.cart_Data">{{cart.cart_Data.length}}</span>
        </i>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <div class="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </button>
        <div class="menu-logo">
            <div class="navbar-brand">
                <span class="navbar-logo">
                    <a routerLink="/">
                        <img src="assets/images/jk-logo-300x69.png" alt="Mobirise" title="" style="height: 3.4rem;">
                    </a>
                </span>
            </div>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav nav-dropdown nav-right w-100" data-app-modern-menu="true">
                <li class="nav-item">
                    <a class="nav-link link text-black display-4" (click)="ScrollToAboutUs()"><span
                            class="mdi-social-person mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>
                        About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link link text-black display-4" routerLink="/shop/lifestyle-general-fitness"><span
                            class="icon54-v1-weight-lift mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>
                        Online Training</a>
                </li>
                <!-- <li class="nav-item"><a class="nav-link link text-black display-4" (click)="ScrollToOnlineTraining()"><span
                            class="socicon socicon-openaigym mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>
                        Lifestyle</a></li> -->
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/shop/contest-prep"><span
                            class="fa fa-trophy mbr-iconfont mbr-iconfont-btn" style="color: rgb(197, 158, 62);"></span>
                        Contest Prep</a></li>
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/shop/ebooks"><span
                            class="fa fa-book mbr-iconfont mbr-iconfont-btn" style="color: rgb(197, 158, 62);"></span>
                        E-Books</a></li>
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/shop/merchandise"><span
                            class="mdi mdi-tshirt-crew mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>Merchandise</a></li>
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/news"><span
                            class="icon54-v3-news mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>News</a></li>
                <!-- <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/testimonial"><span
                                class="mbri-cust-feedback mbr-iconfont mbr-iconfont-btn"
                                style="color: rgb(197, 158, 62);"></span>Testimonial</a></li> -->
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/contact"><span
                            class="mbrib-mobile mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>Contact</a></li>
                <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/testimonial"><span
                            class="mbri-star mbr-iconfont mbr-iconfont-btn"
                            style="color: rgb(197, 158, 62);"></span>T&T</a></li>
            </ul>
            <div class="col-1 w-100 d-none d-lg-flex justify-content-end text-right" id="small">
                <i class="mdi mdi-cart-outline mdi-36px" (click)="goToCart()" style="cursor: pointer;">
                    <span class="badge badge-dark"
                        style="position: absolute;right: 5px;border-radius: 15px;font-style: normal;"
                        *ngIf="cart.cart_Data">{{cart.cart_Data.length}}</span>
                </i>
            </div>
        </div>
    </nav>
</section>