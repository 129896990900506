<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>
<section class="services1 cid-rYRoOy9ztz p-0 pb-5 my-5">
    <div class="container pt-5">
        <div class="row d-flex justify-content-center mt-3">
            <div class="col-12">
                <div class="inst">
                    <h1 class="inst__title align-center mbr-fonts-style mb-4">Thank You!</h1>
                </div>
            </div>
            <div class="col-12">
                <div class="inst">
                    <h3 class="inst__title align-center mbr-fonts-style mb-3">
                        Your order has been confirmed</h3>
                    <h4 class="inst__title align-center text-center mbr-fonts-style mb-3">Please check your email for
                        more details.</h4>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-7 my-5" style="border:1px solid #e6e6e6">
                <h3 class="mbr-section-subtitle mbr-light my-1 py-1 mbr-fonts-style display-5">
                    ORDER SUMMARY
                </h3>
                <div class="row">
                    <hr class="m-0 mb-3" style="border:1px solid #e6e6e6;width:100%;">
                    <div class="col-12 py-2" *ngFor="let i of orderdetail">
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-4 col-lg-3">
                                <img src={{i.shop.productPhoto}} class="img-fluid">
                            </div>
                            <div class="col-8 col-sm-9 col-md-8 col-lg-9">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="align-left mbr-fonts-style fontsm text-left mb-1">
                                            {{i.shop.productName}} <span *ngIf="i.size">({{i.size}})</span>
                                        </h6>
                                        <p *ngIf="i.order_product_price==i.shop.price*i.quantity" class="fontcat pt-0">
                                            {{i.shop.price|currency:'INR':'symbol'}}</p>
                                        <p *ngIf="i.order_product_price!=i.shop.price*i.quantity" class="fontcat pt-0">
                                            <del style="color: red;">{{i.shop.price|currency:'INR':'symbol'}}</del>
                                            {{i.order_product_price|currency:'INR':'symbol'}}
                                        </p>
                                        <p class="fontcat pt-0">Quantity: {{i.quantity}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0 mt-3" style="border:1px solid #e6e6e6;width:100%;">
                    <div class="col-12 d-flex">
                        <div class="row w-100">
                            <div class="col-4">
                                <p class="m-0"><strong>Subtotal:</strong></p>
                            </div>
                            <div class="col-8 d-flex justify-content-end pr-1">
                                <p class="ml-auto m-0 text-right">{{totalProductPrice|currency:'INR':'symbol'}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ordertotal!=totalProductPrice" class="col-12 d-flex">
                        <div class="row w-100">
                            <div class="col-4">
                                <p class="m-0"><strong>Discount:</strong></p>
                            </div>
                            <div class="col-8 d-flex justify-content-end pr-1">
                                <p class="ml-auto m-0 text-right">
                                    {{totalProductPrice-ordertotal|currency:'INR':'symbol'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="row w-100">
                            <div class="col-4">
                                <p class="m-0"><strong>Order Total:</strong></p>
                            </div>
                            <div class="col-8 d-flex justify-content-end pr-1">
                                <p class="ml-auto m-0 text-right">{{ordertotal|currency:'INR':'symbol'}}</p>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="message!='N/A'" class="m-0 mt-3" style="border:1px solid #e6e6e6;width:100%;">
                    <div *ngIf="message!='N/A'" class="col-12 d-flex">
                        <div class="row w-100">
                            <div class="col-4">
                                <p class="m-0"><strong>Message:</strong></p>
                            </div>
                            <div class="col-8 d-flex justify-content-end pr-1">
                                <p class="ml-auto m-0 text-right">{{message}}</p>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0 mt-3" style="border:1px solid #e6e6e6;width:100%;">
                    <div class="col-12 d-flex  pb-3">
                        <div class="row w-100">
                            <div class="col-4">
                                <p class="m-0"><strong>Billing Address:</strong></p>
                            </div>
                            <div class="col-8 d-flex justify-content-end pr-1">
                                <p class="ml-auto m-0 text-right">{{userInfo?.houseNumber}},
                                    {{userInfo?.streetAddress}}, {{userInfo?.area}}, {{userInfo?.city}},
                                    {{userInfo?.state}}-{{userInfo?.postCode}}, {{userInfo?.country}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4 input-group-btn align-center">
                <button type="submit" class="btn btn-primary btn-form display-4" routerLink="/"><i
                        class="mdi mdi-arrow-left btn-form display-4" style="font-weight: 800;"></i><span class="pt-1">
                        &nbsp; BACK TO HOME</span>
                </button>
            </div>
        </div>
    </div>
</section>