<section class="engine"><a href="https://mobirise.info/p">website templates free download</a></section>
<section class="mbr-section content4 cid-rZmqaCDukY" id="content4-17">



    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center pb-3 mbr-fonts-style display-2">
                    Privacy Policy</h2>


            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content1 cid-rZmqbc8pp7" id="content1-18">



    <div class="container">
        <div class="media-container-row">
            <div class="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                <p><strong>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</strong></p>
                <p><span style="font-size: 1rem;">When you purchase something from our store, as part of the buying and
                        selling process, we collect the personal information you give us such as your name, address and
                        email address.</span><br></p>
                <p><span style="font-size: 1rem;">When you browse our store, we also automatically receive your
                        computer’s internet protocol (IP) address in order to provide us with information that helps us
                        learn about your browser and operating system.</span><br></p>
                <p><span style="font-size: 1rem;">Email marketing (if applicable): With your permission, we may send you
                        emails about our store, new products and other updates.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 2 - CONSENT</strong></span><br></p>
                <p><span style="font-size: 1rem;">How do you get my consent?</span><br></p>
                <p><span style="font-size: 1rem;">When you provide us with personal information to complete a
                        transaction, verify your credit card, place an order, arrange for a delivery or return a
                        purchase, we imply that you consent to our collecting it and using it for that specific reason
                        only.</span><br></p>
                <p><span style="font-size: 1rem;">If we ask for your personal information for a secondary reason, like
                        marketing, we will either ask you directly for your expressed consent, or provide you with an
                        opportunity to say no.</span><br></p>
                <p><span style="font-size: 1rem;">How do I withdraw my consent?</span><br></p>
                <p><span style="font-size: 1rem;">If after you opt-in, you change your mind, you may withdraw your
                        consent for us to contact you, for the continued collection, use or disclosure of your
                        information, at anytime, by contacting us at <a
                            href="mailto:info@junaidkaliwala.com">info@junaidkaliwala.com</a>.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 3 - DISCLOSURE</strong></span><br></p>
                <p><span style="font-size: 1rem;">We may disclose your personal information if we are required by law to
                        do so or if you violate our Terms of Service.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 4 - PAYMENT</strong></span><br></p>
                <p><span style="font-size: 1rem;">We use Razorpay for processing payments. We/Razorpay do not store your
                        card data on their servers. The data is encrypted through the Payment Card Industry Data
                        Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used
                        as long as is necessary to complete your purchase transaction. After that is complete, your
                        purchase transaction information is not saved.</span><br></p>
                <p><span style="font-size: 1rem;">Our payment gateway adheres to the standards set by PCI-DSS as managed
                        by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard,
                        American Express and Discover.</span><br></p>
                <p><span style="font-size: 1rem;">PCI-DSS requirements help ensure the secure handling of credit card
                        information by our store and its service providers.</span><br></p>
                <p><span style="font-size: 1rem;">For more insight, you may also want to read terms and conditions of
                        razorpay on https://razorpay.com</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 5 - THIRD-PARTY SERVICES</strong></span><br></p>
                <p><span style="font-size: 1rem;">In general, the third-party providers used by us will only collect,
                        use and disclose your information to the extent necessary to allow them to perform the services
                        they provide to us.</span><br></p>
                <p><span style="font-size: 1rem;">However, certain third-party service providers, such as payment
                        gateways and other payment transaction processors, have their own privacy policies in respect to
                        the information we are required to provide to them for your purchase-related
                        transactions.</span><br></p>
                <p><span style="font-size: 1rem;">For these providers, we recommend that you read their privacy policies
                        so you can understand the manner in which your personal information will be handled by these
                        providers.</span><br></p>
                <p><span style="font-size: 1rem;">In particular, remember that certain providers may be located in or
                        have facilities that are located a different jurisdiction than either you or us. So if you elect
                        to proceed with a transaction that involves the services of a third-party service provider, then
                        your information may become subject to the laws of the jurisdiction(s) in which that service
                        provider or its facilities are located.</span><br></p>
                <p><span style="font-size: 1rem;">Once you leave our store’s website or are redirected to a third-party
                        website or application, you are no longer governed by this Privacy Policy or our website’s Terms
                        of Service.</span><br></p>
                <p><span style="font-size: 1rem;">Links</span><br></p>
                <p><span style="font-size: 1rem;">When you click on links on our store, they may direct you away from
                        our site. We are not responsible for the privacy practices of other sites and encourage you to
                        read their privacy statements.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 6 - SECURITY</strong></span><br></p>
                <p><span style="font-size: 1rem;">To protect your personal information, we take reasonable precautions
                        and follow industry best practices to make sure it is not inappropriately lost, misused,
                        accessed, disclosed, altered or destroyed.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 7 - COOKIES</strong></span><br></p>
                <p><span style="font-size: 1rem;">We use cookies to maintain session of your user. It is not used to
                        personally identify you on other websites.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 8 - AGE OF CONSENT</strong></span><br></p>
                <p><span style="font-size: 1rem;">By using this site, you represent that you are at least the age of
                        majority in your state or province of residence, or that you are the age of majority in your
                        state or province of residence and you have given us your consent to allow any of your minor
                        dependents to use this site.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</strong></span><br>
                </p>
                <p><span style="font-size: 1rem;">We reserve the right to modify this privacy policy at any time, so
                        please review it frequently. Changes and clarifications will take effect immediately upon their
                        posting on the website. If we make material changes to this policy, we will notify you here that
                        it has been updated, so that you are aware of what information we collect, how we use it, and
                        under what circumstances, if any, we use and/or disclose it.</span><br></p>
                <p><span style="font-size: 1rem;">If our store is acquired or merged with another company, your
                        information may be transferred to the new owners so that we may continue to sell products to
                        you.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>QUESTIONS AND CONTACT INFORMATION</strong></span><br></p>
                <p><span style="font-size: 1rem;">If you would like to: access, correct, amend or delete any personal
                        information we have about you, register a complaint, or simply want more information contact our
                        Privacy Compliance Officer at <a
                            href="mailto:info@junaidkaliwala.com">info@junaidkaliwala.com</a>.</span><br></p>
                <p><span style="font-size: 1rem;">[Re: Privacy Compliance Officer]</span><br></p>
                <p><span style="font-size: 1rem;">[622 Manglam Electronic Market Jaipur Rajasthan India
                        302001]</span><br></p>
                <p><span style="font-size: 1rem;">----</span><br></p>
                <p><span style="font-size: 1rem;">p.</span><br></p>
            </div>
        </div>
    </div>
</section>