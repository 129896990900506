import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';
declare var Swiper: any;
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  testimonialFetch: any;

  constructor(private apiservices: ApiService, private spinner: NgxSpinnerService, public constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.testimonial();
  }

  testimonial() {
    var data = ''
    this.apiservices.getRequest(this.constantsService.TESTIMONIAL, data).then(res => {
      let resData = res['data'];
      for (let i = 0; i < resData.length; i++) {
        resData[i].photo = resData[i].photo.replace("/","");
      }
      this.testimonialFetch = resData;
      var swiper = new Swiper('.swiper-container', {
        slidesPerView: 3,
        // slidesPerColumn: 2,
        spaceBetween: 40,
        loop: true,
        speed: 3000,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 1,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }
      });
    })
  }
}
