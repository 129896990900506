import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-ordersucess',
  templateUrl: './ordersucess.component.html',
  styleUrls: ['./ordersucess.component.scss']
})
export class OrdersucessComponent implements OnInit, OnDestroy {

  public userInfo: any;
  public orderdetail: any;
  public ordertotal: any;
  public message: any;
  public getProductPrice: any;
  public totalProductPrice: any;

  constructor(
    public apiService: ApiService) { }

  ngOnDestroy(): void {
    this.apiService.storage.deleteItem('orderdetail')
  }

  async ngOnInit() {
    await this.getUserInfo();
  }

  async getUserInfo() {
    if (await this.apiService.storage.getItem('guestdetail')) {
      let detail = await this.apiService.storage.getItem('guestdetail');
      this.userInfo = detail;
      this.orderProducts();
    }
  }

  async orderProducts() {
    if (await this.apiService.storage.getItem('orderdetail')) {
      let orderDetail = await this.apiService.storage.getItem('orderdetail');
      this.orderdetail = orderDetail['data'];
      this.ordertotal = this.orderdetail[0].order.orderTotalValue;
      this.message = this.orderdetail[0].order.message;
      this.getProductPrice = 0
      this.orderdetail.forEach(element => {
        this.getProductPrice += element.shop.price
      });
      this.totalProductPrice = this.getProductPrice;
    }
  }

}
