<section class="engine"><a href="https://mobirise.info/h">create your own web page for free</a></section><section class="mbr-section content4 cid-rZmr52mWLu" id="content4-1a">

    

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center pb-3 mbr-fonts-style display-2">
                    Return Policy</h2>
            </div>
        </div>
    </div>
</section>
<section class="mbr-section article content1 cid-rZmrf6WuIG" id="content1-1b">



    <div class="container">
        <div class="media-container-row">
            <div class="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                <p><br></p>
                <p><strong>Returns</strong></p>
                <p><span style="font-size: 1rem;">Our policy lasts 30 days. If 30 days have gone by since your purchase,
                        unfortunately we can’t offer you a refund or exchange.</span><br></p>
                <p><span style="font-size: 1rem;">To be eligible for a return, your item must be unused and in the same
                        condition that you received it. It must also be in the original packaging.</span><br></p>
                <p><span style="font-size: 1rem;">Several types of goods are exempt from being returned. Perishable
                        goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept
                        products that are intimate or sanitary goods, hazardous materials, or flammable liquids or
                        gases.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>Additional non-returnable items:</strong></span><br></p>
                <p><span style="font-size: 1rem;">Gift cards</span><br></p>
                <p>Downloadable software products</p>
                <p>Some health and personal care items</p>
                <p><span style="font-size: 1rem;">To complete your return, we require a receipt or proof of
                        purchase.</span><br></p>
                <p><span style="font-size: 1rem;">Please do not send your purchase back to the manufacturer.</span><br>
                </p>
                <p><span style="font-size: 1rem;">There are certain situations where only partial refunds are granted:
                        (if applicable)</span><br></p>
                <p><span style="font-size: 1rem;">Book with obvious signs of use</span><br></p>
                <p><span style="font-size: 1rem;">CD, DVD, VHS tape, software, video game, cassette tape, or vinyl
                        record that has been opened.</span><br></p>
                <p><span style="font-size: 1rem;">Any item not in its original condition, is damaged or missing parts
                        for reasons not due to our error.</span><br></p>
                <p><span style="font-size: 1rem;">Any item that is returned more than 30 days after delivery</span><br>
                </p>
                <p><span style="font-size: 1rem;"><strong>Refunds (if applicable)</strong></span><br></p>
                <p><span style="font-size: 1rem;">Once your return is received and inspected, we will send you an email
                        to notify you that we have received your returned item. We will also notify you of the approval
                        or rejection of your refund.</span><br></p>
                <p><span style="font-size: 1rem;">If you are approved, then your refund will be processed, and a credit
                        will automatically be applied to your credit card or original method of payment, within a
                        certain amount of days.</span><br></p>
                <p><span style="font-size: 1rem;">Late or missing refunds (if applicable)</span><br></p>
                <p><span style="font-size: 1rem;">If you haven’t received a refund yet, first check your bank account
                        again.</span><br></p>
                <p><span style="font-size: 1rem;">Then contact your credit card company, it may take some time before
                        your refund is officially posted.</span><br></p>
                <p><span style="font-size: 1rem;">Next contact your bank. There is often some processing time before a
                        refund is posted.</span><br></p>
                <p><span style="font-size: 1rem;">If you’ve done all of this and you still have not received your refund
                        yet, please contact us at <a
                            href="mailto:info@junaidkaliwala.com">info@junaidkaliwala.com</a>.</span><br></p>
                <p><span style="font-size: 1rem;">Sale items (if applicable)</span><br></p>
                <p><span style="font-size: 1rem;">Only regular priced items may be refunded, unfortunately sale items
                        cannot be refunded.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>Exchanges (if applicable)</strong></span><br></p>
                <p><span style="font-size: 1rem;">We only replace items if they are defective or damaged. If you need to
                        exchange it for the same item, send us an email at <a
                            href="mailto:info@junaidkaliwala.com">info@junaidkaliwala.com</a> and send your item to: 622,
                        Manglam Electronic Market, Jaipur, Rajasthan, India, 302001.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>Gifts</strong></span><br></p>
                <p><span style="font-size: 1rem;">If the item was marked as a gift when purchased and shipped directly
                        to you, you’ll receive a gift credit for the value of your return. Once the returned item is
                        received, a gift certificate will be mailed to you.</span><br></p>
                <p><span style="font-size: 1rem;">If the item wasn’t marked as a gift when purchased, or the gift giver
                        had the order shipped to themselves to give to you later, we will send a refund to the gift
                        giver and he will find out about your return.</span><br></p>
                <p><span style="font-size: 1rem;"><strong>Shipping</strong></span><br></p>
                <p><span style="font-size: 1rem;">To return your product, you should mail your product to: 622, Manglam
                        Electronic Market, Jaipur, Rajasthan, India, 302001.</span><br></p>
                <p><span style="font-size: 1rem;">You will be responsible for paying for your own shipping costs for
                        returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of
                        return shipping will be deducted from your refund.</span><br></p>
                <p><span style="font-size: 1rem;">Depending on where you live, the time it may take for your exchanged
                        product to reach you, may vary.</span><br></p>
                <p><span style="font-size: 1rem;">If you are shipping an item over Rs. 499, you should consider using a
                        trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will
                        receive your returned item.</span><br></p>
            </div>
        </div>
    </div>
</section>