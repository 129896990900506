<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>

<div class="container-fliud d-flex d-md-none">
    <!-- <img src="/assets/images/shops.jpg" class="img-fluid mar5"> -->
</div>


<section class="services1 cid-rYRoOy9ztz  p-0" [id]="i.id" *ngFor="let i of categoryFetch; let a = index">
    <div class="container pt-5">
        <!--Online Training-->
        <div class="row justify-content-center pt-5" *ngIf="i.id==2 && selectedPage==2">
            <!--Titles Online Training-->
            <div class="title col-12">
                <h2 class="align-center pb-3 mbr-fonts-style display-1" style="color:black">
                    {{i.categoryName}}</h2>

            </div>
            <!--Online Training-->
            <div class="card col-6 col-md-6 col-lg-6 Colempty" id="checkcol" *ngFor="let j of productFetch">
                <div *ngIf="j.categoryId == i.id">
                    <!-- <button  class="btn btn-primary display-4" routerLink="/ot"> click</button> -->

                    <div class="card-wrapper p-3">
                        <div class="card-img">
                            <img src="{{j.productPhoto}}" alt="Image" title="" routerLink="/ot/{{j.id}}"
                                style="cursor: pointer;">
                        </div>
                        <div class="card-box " style="padding-bottom: 100px;">
                            <h4 class="card-title mbr-fonts-style display-5" routerLink="/ot/{{j.id}}"
                                style="cursor: pointer;">
                                {{j.productName}}</h4>
                            <p class="mbr-text mbr-fonts-style display-7" [innerHTML]="j.prodcutShortDescription">
                            </p>
                            <a style="margin-bottom:40px ;" routerLink="/ot/{{j.id}}"><p class="m-0">Read More </p></a>
                            <br>
                            <!--Btn-->
                            <div class="mbr-section-btn align-center  alignbottom">
                                <a class=" display-4" style="margin-left: 25px;" *ngIf="j.salePrice != null">
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del>
                                    {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class=" display-4" style="margin-left: 25px;" *ngIf="j.salePrice == null">                
                                    {{j.price|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <br>
                                <a  (click)="cart.addTocart(j.id)" class="btn btn-primary display-4">Add to Cart
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Contest Prep-->
        <div class="row justify-content-center pt-5" *ngIf="i.id==3 && selectedPage==3">
            <!--Titles Contest Prep-->
            <div class="title col-12">
                <h2 class="align-center pb-3 mbr-fonts-style display-1" style="color:black">
                    {{i.categoryName}}</h2>

            </div>
            <!--Contest Prep-->
            <div class="card col-6 col-md-6 col-lg-6 Colempty" id="checkcol" *ngFor="let j of productFetch">
                <div *ngIf="j.categoryId == i.id">
                    <div class="card-wrapper p-3">
                        <div class="card-img">
                            <img src="{{j.productPhoto}}" alt="Image" title="" routerLink="/contest/{{j.id}}"
                                style="cursor: pointer;">
                        </div>
                        <div class="card-box " style="padding-bottom: 100px;">
                            <h4 class="card-title mbr-fonts-style display-5" routerLink="/contest/{{j.id}}"
                                style="cursor: pointer;">{{j.productName}}</h4>
                            <p class="mbr-text mbr-fonts-style display-7" [innerHTML]="j.prodcutShortDescription">
                            </p>
                            <a routerLink="/contest/{{j.id}}" target="_blank"><p>Read More </p></a>
                            <br>
                            <!--Btn-->

                            <div class="mbr-section-btn align-center mt-3 alignbottom">
                                <a class="display-4"
                                    style="margin-left: 5%;" *ngIf="j.salePrice != null" >
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del> 
                                     {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class="display-4"
                                style="margin-left: 5%;" *ngIf="j.salePrice == null" >
                                {{j.price|currency:'INR':'symbol':'3.0-0'}}
                            </a>

                                <br>
                                <a class="display-4" (click)="cart.addTocart(j.id)" class="btn btn-primary display-4">
                                    Add to Cart </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--E-Books-->
        <div class="row justify-content-center pt-5" *ngIf="i.id==4 && selectedPage==4">
            <!--Titles E-Books-->
            <div class="title col-12">
                <h2 class="align-center pb-3 mbr-fonts-style display-1" style="color:black">
                    {{i.categoryName}}</h2>

            </div>
            <!--E-Books-->
            <div class="card  col-6 col-md-4 col-lg-4 Colempty" id="checkcol" *ngFor="let j of productFetch">
                <div *ngIf="j.categoryId == i.id">
                    <div class="card-wrapper p-3">
                        <div class="card-img">
                            <img src="{{j.productPhoto}}" alt="Image" title="" routerLink="/e-books/{{j.id}}"
                                style="cursor: pointer;">
                        </div>
                        <!--desktop-->
                        <div class="card-box d-none d-md-none d-lg-block" style="padding-bottom: 100px;">
                            <h4 class=" mbr-fonts-style display-5" routerLink="/e-books/{{j.id}}"
                                style="cursor: pointer;">
                                {{j.productName}}</h4>
                            <p class="mbr-text mbr-fonts-style display-7" [innerHtml]="j.prodcutShortDescription"></p>
                            <a routerLink="/e-books/{{j.id}}"><p>Read More</p></a>
                            <br><br>
                            <!--Btn desktop-->
                            <div class="mbr-section-btn align-center alignbottom " style="margin-top: 100%;">
                                <a class="display-4"
                                    style="margin-left: 0%;" *ngIf="j.salePrice != null">
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del>
                                    {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class="display-4"
                                style="margin-left: 0%;" *ngIf="j.salePrice == null">
                                {{j.price|currency:'INR':'symbol':'3.0-0'}}                               
                            </a>
                                <div class="btn-group1">
                                    <button type="button" class="btn btn-primary" (click)="cart.addTocart(j.id)">Add to
                                        Cart</button>
                                </div>
                            </div>
                        </div>

                        <!--phone-->
                        <div class="card-box d-block d-md-none d-lg-none" style="padding-bottom: 100px;">
                            <h5 class="card-title mbr-fonts-style display-5" routerLink="/e-books/{{j.id}}"
                                style="cursor: pointer;">{{j.productName}}</h5>
                            <p class="mbr-text mbr-fonts-style" style="font-size: 13px;"
                                [innerHtml]="j.prodcutShortDescription">
                            </p>
                            <a routerLink="/e-books/{{j.id}}"><p>Read More</p></a>
                            <br>
                            <!--Btn phone-->
                            <div class="mbr-section-btn align-center mt-5 alignbottom1 ">
                                <a class="display-4"
                                    style="text-align: center; margin-left: 53px;" *ngIf="j.salePrice != null">
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del>
                                    {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class="display-4"
                                style="text-align: center; margin-left: 53px;" *ngIf="j.salePrice == null">
                               {{j.price|currency:'INR':'symbol':'3.0-0'}}                           
                            </a>
                                <div class="btn-group2">
                                    <button type="button" class="btn btn-primary" (click)="cart.addTocart(j.id)">Add to
                                        Cart</button>
                                </div>
                            </div>

                        </div>
                        <!--ipad-->
                        <div class="card-box d-none d-md-block d-lg-none" style="padding-bottom: 100px;">
                            <h4 class="card-title mbr-fonts-style display-5" routerLink="/e-books/{{j.id}}"
                                style="cursor: pointer;">{{j.productName}}</h4>
                            <p class="mbr-text mbr-fonts-style display-7" [innerHtml]="j.prodcutShortDescription">
                            </p>
                            <a routerLink="/e-books/{{j.id}}" target="_blank">Read More</a>
                            <br>
                            <!--Btn ipad-->
                            <div class="mbr-section-btn align-center mt-5 alignbottom2 ">

                                <a class="display-4"
                                    style="text-align: center; margin-left: 13%;" *ngIf="j.salePrice != null">
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del>
                                    {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class="display-4"
                                style="text-align: center; margin-left: 13%;" *ngIf="j.salePrice == null">
                                {{j.price|currency:'INR':'symbol':'3.0-0'}}
                            </a>
                                <div class="btn-group1">
                                    <button type="button" class="btn btn-primary" (click)="cart.addTocart(j.id)">Add to
                                        Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Merchandise-->
        <div class="row justify-content-center pt-5" *ngIf="i.id==5 && selectedPage==5">
            <!--Titles Merchandise-->
            <div class="title col-12">
                <h2 class="align-center pb-3 mbr-fonts-style display-1" style="color:black">
                    {{i.categoryName}}</h2>

            </div>
            <!--Merchandise-->
            <div class="card col-6 col-md-6 col-xl-3 Colempty" id="checkcol" *ngFor="let j of productFetch">
                <div *ngIf="j.categoryId == i.id">
                    <div class="card-wrapper p-3">
                        <div class="card-img">
                            <img src="{{j.productPhoto}}" alt="Image" title="">
                        </div>
                        <div class="card-box" style="padding-bottom: 100px;">
                            <h4 class="card-title mbr-fonts-style display-5 topp" style=" 
                            white-space: nowrap;">{{j.productName}}</h4>
                            <p class="mbr-text mbr-fonts-style display-7" [innerHTML]="j.productDescription">
                            </p>
                            <div class="row align-items-center pr-3" style="margin-top: 23px;"
                                *ngIf="j.Small>0 || j.Medium>0 || j.Large>0 || j.XL>0 || j.XLL>0">
                                <div class="col-auto">
                                    <h5 class="mbr-text mbr-fonts-style mb-0">Size: </h5>
                                </div>
                                <div class="col text-center bordergray p-1 pointer"
                                    [ngStyle]="{'border-color' :j.size == 'Small' ? j.colors:'#767676','color' :j.size == 'Small' ? j.colors:'#767676'}"
                                    (click)="Getsize('Small',j.id)" *ngIf="j.Small ">
                                    S
                                </div>
                                <div class="col text-center bordergray p-1 pointer"
                                    [ngStyle]="{'border-color' :j.size == 'Medium' ? j.colors:'#767676','color' :j.size == 'Medium' ? j.colors:'#767676'}"
                                    (click)="Getsize('Medium',j.id)" *ngIf="j.Medium">
                                    M
                                </div>
                                <div class="col text-center bordergray p-1 pointer"
                                    [ngStyle]="{'border-color' :j.size == 'Large' ? j.colors:'#767676','color' :j.size == 'Large' ? j.colors:'#767676'}"
                                    (click)="Getsize('Large',j.id)" *ngIf="j.Large">
                                    L
                                </div>
                                <div class="col text-center bordergray p-1 pointer"
                                    [ngStyle]="{'border-color' :j.size == 'XL' ? j.colors:'#767676','color' :j.size == 'XL' ? j.colors:'#767676'}"
                                    (click)="Getsize('XL',j.id)" *ngIf="j.XL">
                                    XL
                                </div>
                                <div class="col text-center bordergray p-1 pointer"
                                [ngStyle]="{'border-color' :j.size == 'XXL' ? j.colors:'#767676','color' :j.size == 'XXL' ? j.colors:'#767676'}"
                                (click)="Getsize('XXL',j.id)" *ngIf="j.XXL">
                                XXL
                            </div>
                               
                            </div><br>
                            <!--Btn-->
                            <div *ngIf="j.Small>0 || j.Medium>0 || j.Large>0 || j.XL>0 || j.XLL>0"
                                class="mbr-section-btn align-center mt-3 alignbottom text-center">
                                <a class="display-4"
                                    style="margin-left: 30px;"  *ngIf="j.salePrice != null">
                                    <del>{{j.price|currency:'INR':'symbol':'3.0-0'}}</del>
                                    {{j.salePrice|currency:'INR':'symbol':'3.0-0'}}
                                </a>
                                <a class="display-4"
                                style="margin-left: 30px;"  *ngIf="j.salePrice == null">
                               {{j.price|currency:'INR':'symbol':'3.0-0'}}
                            </a>
                                <br>
                                <a (click)="AddMerchtocart(j.id)" *ngIf="merchsize"
                                    class="btn btn-primary display-4 add " >
                                    Add to Cart
                                </a>

                                <a (click)="Setsize()" *ngIf="!merchsize" class="btn btn-primary display-4"> Add to Cart

                                </a>
                            </div>
                            <div *ngIf="0>=j.Small && 0>=j.Medium && 0>=j.Large && 0>=j.XL"
                                class="mbr-section-btn align-center mt-3 alignbottom"><a
                                    class="btn btn-primary display-4 px-3 phone tab">Out Of Stock
                                </a></div>
                            <!-- style="width: p122px; t146px" -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
